<template>
  <v-container fluid>
    <v-row>
      <v-col class="pt-3 pb-2">
        <h1>Add / Update Hours and mileage to the Daily Return Sheet</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h4>Update hours:</h4>
      </v-col>
    </v-row>
    <v-row v-for="(worker, index) in drs.daily_return_sheets_workers" :key="`w_${index}`">
      <v-col class="col-sm-3 pt-3 pb-2">
        <label>
          {{ worker.crew_member.personnel.first_name }}
          {{ worker.crew_member.personnel.last_name }}
          hours
        </label>
        <v-select :items="addHours" v-model="worker.newHours"></v-select>
      </v-col>
      <v-col class="col-sm-3 pt-3 pb-2">
        <label>
          {{ worker.crew_member.personnel.first_name }}
          {{ worker.crew_member.personnel.last_name }}
          minutes
        </label>
        <v-select :items="addMinutes" v-model="worker.newMinutes"></v-select>
      </v-col>
      <v-col class="col-sm-2 pt-3 pb-2">
        <v-checkbox
          class="mt-0 pt-0"
          :label="`${worker.crew_member.personnel.first_name} ${ worker.crew_member.personnel.last_name } overnight stay?`"
          :ripple="false" v-model="worker.overnight"></v-checkbox>
      </v-col>
      <v-col class="col-sm-2 pt-3 pb-2">
        <v-checkbox
          class="mt-0 pt-0"
          :label="`${worker.crew_member.personnel.first_name} ${ worker.crew_member.personnel.last_name } working away? (+50%)`"
          :ripple="false" v-model="worker.working_away"></v-checkbox>
      </v-col>
      <v-col class="col-sm-2 pt-3 pb-2">
        <v-checkbox
          class="mt-0 pt-0"
          :label="`${worker.crew_member.personnel.first_name} ${ worker.crew_member.personnel.last_name } working nights? (+33%)`"
          :ripple="false" v-model="worker.working_nights"></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-sm-3 pt-3 pb-2">
        <v-text-field v-model="drs.mileage" label="Mileage"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-sm-3 pt-3 pb-2">
        <v-btn @click="save">Save</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'AddHours',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      drs: {
        quotation: {},
      },
      addHours: [
        { value: 0, text: 0 },
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
        { value: 4, text: 4 },
        { value: 5, text: 5 },
        { value: 6, text: 6 },
        { value: 7, text: 7 },
        { value: 8, text: 8 },
        { value: 9, text: 9 },
        { value: 10, text: 10 },
        { value: 11, text: 11 },
        { value: 12, text: 12 },
        { value: 13, text: 13 },
        { value: 14, text: 14 },
        { value: 15, text: 15 },
        { value: 16, text: 16 },
        { value: 17, text: 17 },
        { value: 18, text: 18 },
        { value: 19, text: 19 },
        { value: 20, text: 20 },
        { value: 21, text: 21 },
        { value: 22, text: 22 },
        { value: 23, text: 23 },
      ],
      addMinutes: [
        { value: 1, text: '00' },
        { value: 2, text: '25' },
        { value: 3, text: '50' },
        { value: 4, text: '75' },
      ],
    };
  },
  methods: {
    save() {
      const postData = {};
      postData.drs = this.drs;
      axios.post(`/dailyReturnSheets/saveHours.json?token=${this.token}`, postData)
        .then(() => {
          this.$router.go('-1');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDrs() {
      const drsId = this.$route.params.id;
      axios.get(`/dailyReturnSheets/getSingle/${drsId}.json?token=${this.token}`)
        .then((response) => {
          this.drs = response.data.dailyReturnSheet;
          for (let i = 0; i < this.drs.daily_return_sheets_workers.length; i += 1) {
            let time = this.drs.daily_return_sheets_workers[i].hours;
            time = time.toString();
            const hours = parseInt(time.split('.')[0]);
            let minutes = time.split('.')[1];
            console.log(minutes);
            if (!minutes) minutes = 1;
            if (minutes === '25') minutes = 2;
            if (minutes === '5') minutes = 3;
            if (minutes === '75') minutes = 4;
            this.drs.daily_return_sheets_workers[i].newHours = hours;
            this.drs.daily_return_sheets_workers[i].newMinutes = minutes;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getDrs();
  },
};
</script>